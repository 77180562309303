import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-icon.svg";
import blog1 from "../../images/rudk/blogs/blog-1.jpg"
import blog2 from "../../images/rudk/blogs/blog-2.jpeg"
import blog3 from "../../images/rudk/blogs/blog-3.jpeg"

const Container = tw.div`relative pb-16 bg-gray-200`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded-2xl`
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
const BaseLink = tw.a`inline-flex justify-center xl:justify-start items-center mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
const Link = styled(BaseLink)`
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

export default () => {
  const blogPosts = [
    {
      imageSrc: blog2,
      category: "Article",
      title: "Cost Cutting through Energy Saving for SMEs",
      url: "https://www.swisscontact.org/en/news/cost-cutting-through-energy-saving-for-smes"
    },
    {
      imageSrc: blog3,
      category: "Article",
      title: "परिसंघ र राष्ट्रिय ऊर्जा दक्ष केन्द्रबीच सहकार्य गर्ने समझदारी",
      url: "https://cni.org.np/parasagha-ra-rashhataraya-uuraja-thakashha-kanatharabca-sahakaraya-garana-samajhathara"
    },
    {
      imageSrc: blog1,
      category: "Podcast",
      title: "Energy, Sources, Consumption | Amrit Abhamani Dhakal",
      url: "https://youtu.be/rlCfq5qrRRU?si=OYmpgLNsrFh4-_ze",
      actionText: "View"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Blogs | News | Articles</HeadingTitle>
          {/* <HeadingDescription></HeadingDescription> */}
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} loading="lazy"/>
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Link target="_black" href={post.url}>{post.actionText || "Read post"}<ArrowRightIcon /></Link>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
