import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionDescription } from "components/misc/Typography.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import training1 from "images/rudk/research/training1.jpg";
import training2 from "images/rudk/research/training2.jpg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-evenly max-w-screen-2xl mx-auto py-5 md:py-6`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;

const Description = tw(SectionDescription)`text-lg text-justify max-w-6xl px-2`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/2 max-w-lg`}
`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-96 bg-cover bg-center rounded-2xl`
]);

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full px-2 py-4`}
  .textContainer {
    ${tw`mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;


export default ({ 
  cards = null, 
  heading = "", 
  subheading = "", 
  description1 = "",
  description2 = ""
}) => {
  const defaultCards = [
    { // 1
      imageSrc: training1,
      title: "",
    },
    { // 2
      imageSrc: training2,
      title: "",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description1 && <Description>{description1}</Description>}
        {description2 && <Description>{description2}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <Image imageSrc={card.imageSrc} loading="lazy" />
              <span className="textContainer">
                <span className="title">{card.title}</span>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
