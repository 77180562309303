import React, { forwardRef, useEffect, useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import solar from "images/rudk/landing-carousel/solar.jpeg";
import industries from "images/rudk/landing-carousel/industries.jpeg";
import bpotc from "images/rudk/landing-carousel/bpotc.jpeg";
import building from "images/rudk/landing-carousel/building.jpg";
import sahaj from "images/rudk/landing-carousel/sahaj.jpeg";
import substainableFinancing from "images/rudk/landing-carousel/sustainable-financing.jpeg";

import "slick-carousel/slick/slick.css";
import { animate, stagger } from "framer-motion";

const Container = tw.div`relative`;

const TestimonialSliderContainer = tw.div`mt-16`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:justify-center md:items-center outline-none`;
const ImageContainer = styled.div`
  ${tw`flex items-center`}
  img {
    ${tw`w-full h-96 md:h-128 lg:h-160 xl:h-192 object-cover`}
  }
`;
const Overlay = tw.div`absolute w-full h-full flex justify-start items-end from-green-800/75 from-10% to-60% bg-gradient-to-t`;
const TextWrapper = tw.div`flex flex-col ml-8 mb-8 xl:mb-24`;
const OverlayTextTitle = tw.p`font-bold text-white text-2xl md:text-3xl text-left mb-3`;
const OverlayText = tw.p`font-bold text-white text-base md:text-lg text-left mb-3`;
const GradientBG = tw.div`w-80 h-80 from-red-300/50 from-10% to-green-300/50 to-100% bg-gradient-to-r`;

export default () => {
  const carousels = [
    {
      imageSrc: sahaj,
      title: "Achieving Excellence in Competitive Ways",
      description: "Winner of Round 5 theme of SCF, Climate and Environmentally sustainable services aiming to identify energy-saving opportunities and facilitate a transition to sustainable energy sources for agriculture processors, businesses and non-agriculture manufacturing companies of the Koshi Province"
    },
    {
      imageSrc: industries,
      title: "Energy Audit and Energy Management in Industries",
      description: "Energy Efficiency and Energy Management System in Industries can play a major role to achieve reduction in energy demands while ensuring continual development"
    },
    {
      imageSrc: solar,
      title: "Renewable Energy and Development",
      description: "From the reduced carbon emission and air pollution to enhancing energy access, reliability, security and resilience of the power, renewable energy can play a part in the development through economic, environmental and social advantages.",
      description2: "Site: Tatopani Rural Municipality, Humla"
    },
    {
      imageSrc: building,
      title: "Energy Efficiency in Buildings",
      description: "Residential and Commercial buildings consume approximately 40% of the Global Energy offering higher potential for achieving significant GHG emission reduction in developing countries like Nepal."
    },
    {
      imageSrc: bpotc,
      title: "Knowledge Transfer and Capacity Development",
      description: "Prioritizing the transfer of knowledge to create an awareness amongst public, build a commendable taskforce to achieve the cleaner goals faster."
    },
    {
      imageSrc: substainableFinancing,
      title: "Sustainable Financing Principles for a Sustainable Future",
      description: "Contributing towards a transformational shift by its services to a greener, more equitable and sustainable future. Dialogues on energy savings, financial models and reducing emissions."
    },
  ];

  const afterChangeHandler = () => {
  }

  const beforeChangeHandler = () => {
    animate([
      [".carousel-text-wrapper", { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" }, { type: "spring", duration: 0.2 }],
      [".carousel-text-wrapper", { transform: "scale(1)", opacity: 1, filter: "blur(0px)"}, { type: "spring", delay: 0.5, duration: 0.5 }],
    ]);
  }

  return (
    <Container>
      {/* <GradientBG /> */}
      <TestimonialSliderContainer>
        <TestimonialSlider
          arrows={false}
          autoplay={true}
          autoplaySpeed={4000}
          // className={"center"}
          // centerMode={true}
          // dots={true}
          fade={true}
          infinite={true}
          pauseOnHover={false}
          slidesToScroll={1}
          speed={2000}
          // cssEase="linear"
          beforeChange={() => {
            beforeChangeHandler();
          }}
          afterChange={() => {
            afterChangeHandler();
          }}
          onInit={() => {
            beforeChangeHandler();
          }}
        >
          {carousels.map((testimonial, index) => (
            <Testimonial key={index}>
              <ImageContainer className="image-container">
                <Overlay>
                  <TextWrapper className={"carousel-text-wrapper"}>
                    <OverlayTextTitle className="carousel-title-text">{testimonial.title}</OverlayTextTitle>
                    <OverlayText className="carousel-item-description">{testimonial.description}</OverlayText>
                    {testimonial.description2 && <OverlayText className="carousel-item-description">{testimonial.description2}</OverlayText>}
                  </TextWrapper>
                </Overlay>
                <img src={testimonial.imageSrc} alt={testimonial.description} loading="lazy"/>
              </ImageContainer>
            </Testimonial>
          ))}
        </TestimonialSlider>
      </TestimonialSliderContainer>

    </Container>
  );
};
