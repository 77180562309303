import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

export const Container = tw.div`relative`;
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto max-w-3xl`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-5 flex lg:justify-center max-w-screen-lg mx-auto flex-col sm:flex-row`}
`;

const Image = styled.img`
  ${tw`hidden sm:block w-auto h-48 md:h-48 lg:h-72 xl:h-96 w-full rounded basis-1/3`}
`;

const TextContainer = tw.div`flex flex-col p-4 justify-center items-center max-w-80 m-auto basis-1/3`
const Title = tw.p`text-xl font-bold my-0 flex flex-col items-center`;
const TitleOrange = tw(Title)`text-orange-500`;
const TitleBlue = tw(Title)`text-blue-500`;
const TitleGreen = tw(Title)`text-green-500`;
const Icon = styled.img`
  ${tw`h-12 w-12`}
`;
const Details = tw(Description)`text-justify`;

export default ({
  heading = "",
  subheading = "",
  description = "",
  imageSrc = "",
  cards,
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          <TextContainer>
            <TitleOrange>
              <Icon src={cards[0].imageSrc} />
              {cards[0].title}
            </TitleOrange>
            <Details>{cards[0].description}</Details>
          </TextContainer>
          <Image src={imageSrc} loading="lazy"/>
          <TextContainer>
            <TitleBlue>
              <Icon src={cards[1].imageSrc} />{cards[1].title}
            </TitleBlue>
            <Details>{cards[1].description}</Details>
          </TextContainer>
        </ThreeColumnContainer>
        <TextContainer>
          <TitleGreen>
            <Icon src={cards[2].imageSrc} />
            {cards[2].title}
          </TitleGreen>
          <Details>{cards[2].description}</Details>
        </TextContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
