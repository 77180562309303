import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import Solar from "images/rudk/solar.jpg";
import brickKlins from "images/rudk/industries/brick-klins.jpeg";
import cement from "images/rudk/industries/cement.jpg";
import commercial from "images/rudk/industries/commercial.jpeg";
import construction from "images/rudk/industries/construction.jpeg";
import dairy from "images/rudk/industries/dairy.jpeg";
import distillary from "images/rudk/industries/distillary.jpeg";
import food from "images/rudk/industries/food.jpg";
import metalSteels from "images/rudk/industries/metal-steels.jpeg";
import msmes from "images/rudk/industries/msmes.jpeg";
import pharmaceuticals from "images/rudk/industries/pharmaceuticals.jpeg";
import pulpPaper from "images/rudk/industries/pulp-paper.jpg";

// const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const Container = tw.div`relative  -mx-8 px-8 text-gray-100 bg-gradient-to-b from-green-700 from-0%  to-blue-700 to-100%`; 

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 max-w-xs`}
`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-64 bg-cover bg-center border-4 border-solid border-gray-200 rounded-4xl`
]);

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full px-2 py-2 mb-2`}

  .textContainer {
    ${tw`mt-2 flex flex-col w-full items-center`}
  }

  .title {
    ${tw`tracking-wide font-bold text-2xl leading-none`}
  }
`;

export default ({
  cards = null,
  heading = "Our services across Industries",
  subheading = "",
  description = ""
}) => {

  const defaultCards = [
    {
      imageSrc: dairy,
      title: "Dairy",
    },
    { imageSrc: brickKlins, title: "Brick Klins" },
    { imageSrc: cement, title: "Cement" },
    { imageSrc: commercial, title: "Commercial Building" },
    { imageSrc: distillary, title: "Distillary" },
    { imageSrc: food, title: "Food and Beverage" },
    { imageSrc: metalSteels, title: "Metal and steels" },
    { imageSrc: msmes, title: "MSMEs" },
    { imageSrc: pharmaceuticals, title: "Pharmaceuticals" },
    { imageSrc: pulpPaper, title: "Pulp and Paper" },
    { imageSrc: construction, title: "Construction" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
                <Image imageSrc={card.imageSrc} loading="lazy" />
              <span className="textContainer">
                <span className="title">{card.title}</span>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
